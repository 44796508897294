.cometchat-call-log-participants {
    width: 100%;
}

.cometchat-call-log-participants .cometchat-date {
    padding: 0px;
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-regular);
}

.cometchat-call-log-participants__trailing-view {
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-medium);
}

.cometchat-call-log-participants__trailing-view-disabled {
    overflow: hidden;
    color: var(--cometchat-text-color-tertiary, #A1A1A1);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-medium);
}

.cometchat-call-log-participants .cometchat-list-item__trailing-view {
    width: fit-content;
}