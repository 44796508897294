.cometchat-call-log-info {
    width: 100%;
    padding-bottom: var(--cometchat-padding-3, 12px);
}

.cometchat-call-log-info .cometchat-list-item {
    background: var(--cometchat-background-color-03, #F5F5F5);
}

.cometchat-call-log-info .cometchat-list-item__leading-view,
.cometchat-call-log-info .cometchat-avatar,
.cometchat-call-log-info .cometchat-avatar__image {
    height: 16px;
    width: 16px;
    background: transparent;
    border-radius: 0px;
}

.cometchat-call-log-info__subtitle .cometchat-date {
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-regular);
    padding: 0px;
}

.cometchat-call-log-info .cometchat-call-log-info__trailing-view {
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-medium);
}

.cometchat-call-log-info .cometchat-call-log-info__trailing-view-disabled {
    overflow: hidden;
    color: var(--cometchat-text-color-tertiary, #A1A1A1);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-medium);
}

.cometchat-call-log-info .cometchat-list-item__trailing-view {
    width: fit-content;
}