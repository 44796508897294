.cometchat-call-log-details {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-call-log-details__header {
    display: flex;
    height: 64px;
    min-height: 64px;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    align-self: stretch;
    border-bottom: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-overflow: ellipsis;
    font: var(--cometchat-font-heading2-bold);
}

.cometchat-call-log-details__call-log-item .cometchat-list-item:hover {
    background: transparent;
    cursor: default;
}

.cometchat-call-log-details__header-text {
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-overflow: ellipsis;
    font: var(--cometchat-font-heading2-bold, 700 20px Roboto);
}

.cometchat-call-log-details .cometchat-message-header {
    height: fit-content;
    padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px) var(--cometchat-padding-5, 20px) var(--cometchat-padding-4, 16px);
}

.cometchat-call-log-details .cometchat-call-log-details__subtitle {
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-regular);
}

.cometchat-call-log-details .cometchat-call-log-details__call-log-item .cometchat-list-item__trailing-view {
    height: fit-content;
}

.cometchat-call-log-details .cometchat-call-button {
    gap: var(--cometchat-padding-2, 8px);
}

.cometchat-call-log-details .cometchat-call-button__voice,
.cometchat-call-log-details .cometchat-call-button__video {
    display: flex;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--cometchat-radius-2, 8px);
    border: 1px solid var(--cometchat-border-color-default, #E8E8E8);
}

.cometchat-call-log-details .cometchat-call-button .cometchat-button__icon {
    background: var(--cometchat-icon-color-highlight);
}

.cometchat-call-log-details__call-log-item {
    width: 100%;
}

.cometchat-call-log-details__call-log-item .cometchat-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-5, 20px);
    flex: 1 0 0;
}

.cometchat-call-log-details .cometchat-message-header .cometchat-list-item .cometchat-list-item__leading-view,
.cometchat-call-log-details__call-log-item .cometchat-avatar,
.cometchat-call-log-details__call-log-item .cometchat-avatar__image {
    height: 60px;
    width: 60px;
}

.cometchat-outgoing-call__avatar .cometchat-avatar,
.cometchat-outgoing-call__avatar .cometchat-avatar__image {
    width: 160px;
    height: 160px;
}

.cometchat-call-log-details__call-log-item .cometchat-avatar__text {
    font: var(--cometchat-font-heading1-bold, 700 24px Roboto);
}

.cometchat-call-log-details__tabs {
    display: flex;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--cometchat-border-color-default, #E8E8E8);
}

.cometchat-call-log-details__tabs-tab-item {
    display: flex;
    height: 40px;
    padding: var(--cometchat-padding-2, 8px);
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    flex: 1 0 0;
    border-radius: 4px 4px 0px 0px;
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    font: var(--cometchat-font-heading4-medium, 500 16px Roboto);
    cursor: pointer;
}

.cometchat-call-log-details__tabs-tab-item-active {
    display: flex;
    height: 40px;
    padding: var(--cometchat-padding-2, 8px);
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    flex: 1 0 0;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 2px solid var(--cometchat-primary-color, #6852D6);
    color: var(--cometchat-text-color-highlight, #6852D6);
    text-align: center;
    font: var(--cometchat-font-heading4-medium, 500 16px Roboto);
}

.cometchat-call-log-details__header-back {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-mask: url('../../assets/arrow_back.svg');
    -webkit-mask-size: contain;
    background: var(--cometchat-icon-color-primary);
    cursor: pointer;
}

@media (max-width: 768px) {
    .cometchat-call-log-details {
        width: 100vw;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;
    }

    .cometchat-call-log-details__header-back {
        display: block;
    }

    .cometchat-call-log-details .cometchat-message-header__back-button {
        display: none;
    }

    .cometchat-call-log-details .cometchat-call-button__voice,
    .cometchat-call-log-details .cometchat-call-button__video {
        padding: var(--cometchat-padding-2, 8px);
    }
}

@media (min-width: 769px) {
    .cometchat-call-log-details__header-back {
        display: none;
    }
}